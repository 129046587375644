/*eslint-disable*/
import React from "react"
// react components for routing our app without refresh
import { Link } from "gatsby"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"

// React icons
import { FaFacebook, FaCalculator } from "react-icons/fa"
import { MdRestaurantMenu, MdMessage } from "react-icons/md"

// core components
import Button from "components/CustomButtons/Button.jsx"

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx"

function HeaderLinks({ ...props }) {
  const { classes } = props
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/menu/" className={classes.navLink}>
          <Button color="transparent" className="p-0">
            <MdRestaurantMenu /> menú
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/cotizador/" className={classes.navLink}>
          <Button color="transparent" className="p-0">
            <FaCalculator className={classes.icons} /> Cotizador en línea
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contacto/" className={classes.navLink}>
          <Button color="transparent" className="p-0">
            <MdMessage className={classes.icons} /> Contacto
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Síguenos en facebook"
          placement={
            typeof window !== "undefined" && window.innerWidth > 959
              ? "top"
              : "left"
          }
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/CateringGlumiere/"
            target="_blank"
            className={classes.navLink}
            style={{ paddingTop: "19.5px", paddingBottom: "19.5px" }}
          >
            <FaFacebook />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  )
}

export default withStyles(headerLinksStyle)(HeaderLinks)
