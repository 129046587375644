import React from "react"
import PropTypes from "prop-types"

import Footer from "components/Footer/Footer.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import MyHeader from "./MyHeader"
import image from "../images/b1.jpg"
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx"

const LayoutCard = props => {
  const { classes } = props
  return (
    <>
      <MyHeader />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <main>{props.children}</main>
        </div>
        <Footer whiteFont style={{ position: "relative" }} />
      </div>
    </>
  )
}

LayoutCard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withStyles(loginPageStyle)(LayoutCard)
