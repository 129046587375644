import React from "react"
import Header from "components/Header/Header.jsx"
import HeaderLinks from "components/Header/HeaderLinks.jsx"

const MyHeader = () => {
  return (
    <Header
      brand="Catering Glumière"
      rightLinks={<HeaderLinks />}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 100,
        color: "dark",
      }}
    />
  )
}

export default MyHeader
